<div class="alfresco-mobile-application-container">
  <span>{{ 'APP.DIALOGS.MOBILE_APP.OPEN_ALFRESCO_MOBILE_APP' | translate }}</span>
  <button mat-button class="cross-button" (click)="onCloseDialog()">
    <mat-icon class="cross-icon">close</mat-icon>
  </button>
</div>

<div class="open-in-app-container">
  <button mat-button (click)="openInApp()" data-automation-id="open-in-app-button" class="open-in-app" cdkFocusInitial>
    <span>{{ 'APP.DIALOGS.MOBILE_APP.MOBILE_APP_BUTTON_LABEL' | translate }}</span>
  </button>
</div>

<div class="download-app-container" *ngIf="appStoreUrl">
  <button mat-button data-automation-id="download-app-button" class="download-app-button" (click)="downloadIosApp()">
    <span>{{ 'APP.DIALOGS.MOBILE_APP.DOWNLOAD_APP_BUTTON_LABEL' | translate }}</span>
  </button>
</div>
