<aca-page-layout>
  <aca-page-layout-header>
    <aca-search-input></aca-search-input>
    <div class="adf-toolbar--spacer adf-toolbar-divider"></div>
    <adf-toolbar class="adf-toolbar--inline">
      <ng-container *ngFor="let entry of actions; trackBy: trackByActionId">
        <aca-toolbar-action [actionRef]="entry"></aca-toolbar-action>
      </ng-container>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="main-content">
      <div class="adf-search-results">
        <div class="adf-search-results__content">
          <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"> </mat-progress-bar>
          <div class="adf-search-results__content-header content" *ngIf="data?.list.entries.length">
            <div class="content__side--left">
              <div class="adf-search-results--info-text" *ngIf="totalResults !== 1">
                {{ 'APP.BROWSE.SEARCH_LIBRARIES.FOUND_RESULTS' | translate: { number: totalResults } }}
              </div>
              <div class="adf-search-results--info-text" *ngIf="totalResults === 1">
                {{ 'APP.BROWSE.SEARCH_LIBRARIES.FOUND_ONE_RESULT' | translate: { number: totalResults } }}
              </div>
            </div>
          </div>

          <adf-document-list
            #documentList
            acaContextActions
            acaDocumentList
            [showHeader]="showHeader"
            [selectionMode]="'single'"
            [sorting]="['name', 'asc']"
            [node]="data"
            [imageResolver]="imageResolver"
            (node-dblclick)="handleNodeClick($event)"
            (name-click)="handleNodeClick($event)"
          >
            <data-columns>
              <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
                <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                  <data-column
                    [key]="column.key"
                    [title]="column.title"
                    [type]="column.type"
                    [format]="column.format"
                    [class]="column.class"
                    [sortable]="column.sortable"
                  >
                    <ng-template let-context>
                      <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                    </ng-template>
                  </data-column>
                </ng-container>

                <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                  <data-column
                    [key]="column.key"
                    [title]="column.title"
                    [type]="column.type"
                    [format]="column.format"
                    [class]="column.class"
                    [sortable]="column.sortable"
                  >
                  </data-column>
                </ng-container>
              </ng-container>
            </data-columns>

            <adf-custom-empty-content-template>
              <ng-container *ngIf="data">
                <div class="empty-search__block" aria-live="polite">
                  <p class="empty-search__text">
                    {{ 'APP.BROWSE.SEARCH.NO_RESULTS' | translate }}
                  </p>
                </div>
              </ng-container>
            </adf-custom-empty-content-template>
          </adf-document-list>

          <adf-pagination *ngIf="totalResults > 0" acaPagination [target]="documentList" (change)="onPaginationChanged($event)">
          </adf-pagination>
        </div>
      </div>
    </div>
    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
