<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.LIBRARIES.MENU.FAVORITE_LIBRARIES.TITLE"> </adf-breadcrumb>

    <adf-toolbar class="adf-toolbar--inline">
      <ng-container *ngFor="let entry of actions; trackBy: trackByActionId">
        <aca-toolbar-action [actionRef]="entry"></aca-toolbar-action>
      </ng-container>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="main-content">
      <adf-document-list
        #documentList
        acaDocumentList
        acaContextActions
        [display]="documentDisplayMode$ | async"
        [node]="$any(list)"
        [loading]="isLoading"
        [selectionMode]="'single'"
        [navigate]="false"
        [sorting]="['title', 'asc']"
        [sortingMode]="'client'"
        (node-dblclick)="handleNodeClick($event)"
        [imageResolver]="imageResolver"
        (name-click)="handleNodeClick($event)"
      >
        <adf-custom-empty-content-template>
          <adf-empty-content
            icon="library_books"
            [title]="'APP.BROWSE.LIBRARIES.EMPTY_STATE.FAVORITE_LIBRARIES.TITLE'"
            subtitle="APP.BROWSE.LIBRARIES.EMPTY_STATE.FAVORITE_LIBRARIES.TEXT"
          >
          </adf-empty-content>
        </adf-custom-empty-content-template>

        <data-columns>
          <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
            <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
                <ng-template let-context>
                  <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                </ng-template>
              </data-column>
            </ng-container>

            <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
              </data-column>
            </ng-container>
          </ng-container>
        </data-columns>
      </adf-document-list>

      <adf-pagination
        [target]="documentList"
        [pagination]="pagination"
        (changePageSize)="onChangePageSize($event)"
        (changePageNumber)="onChange($event)"
        (nextPage)="onChange($event)"
        (prevPage)="onChange($event)"
      >
      </adf-pagination>
    </div>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
