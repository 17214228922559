<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.SHARED.TITLE"></adf-breadcrumb>

    <adf-toolbar class="adf-toolbar--inline">
      <ng-container *ngFor="let entry of actions; trackBy: trackByActionId">
        <aca-toolbar-action [actionRef]="entry"></aca-toolbar-action>
      </ng-container>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="main-content">
      <adf-document-list
        #documentList
        acaDocumentList
        acaContextActions
        [display]="documentDisplayMode$ | async"
        [currentFolderId]="'-sharedlinks-'"
        [selectionMode]="'multiple'"
        [sorting]="['modifiedAt', 'desc']"
        [imageResolver]="imageResolver"
        [sortingMode]="'client'"
        (node-dblclick)="handleNodeClick($event)"
        (name-click)="handleNodeClick($event)"
      >
        <adf-custom-empty-content-template>
          <adf-empty-content icon="people" [title]="'APP.BROWSE.SHARED.EMPTY_STATE.TITLE'" subtitle="APP.BROWSE.SHARED.EMPTY_STATE.TEXT">
          </adf-empty-content>
        </adf-custom-empty-content-template>

        <data-columns>
          <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
            <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
                <ng-template let-context>
                  <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                </ng-template>
              </data-column>
            </ng-container>

            <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
              </data-column>
            </ng-container>
          </ng-container>
        </data-columns>
      </adf-document-list>

      <adf-pagination acaPagination [target]="documentList"> </adf-pagination>
    </div>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
