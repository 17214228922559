<aca-page-layout [hasError]="!isValidPath">
  <aca-page-layout-header>
    <adf-breadcrumb [root]="title" [folderNode]="node" [maxItems]="isSmallScreen ? 1 : 0" (navigate)="onBreadcrumbNavigate($event)"> </adf-breadcrumb>

    <adf-toolbar class="adf-toolbar--inline">
      <ng-container *ngFor="let entry of actions; trackBy: trackByActionId">
        <aca-toolbar-action [actionRef]="entry"></aca-toolbar-action>
      </ng-container>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="main-content" *ngIf="!(showLoader$ | async)">
      <adf-upload-drag-area [rootFolderId]="node?.id" [disabled]="!canUpload" (updateFileVersion)="onUploadNewVersion($event)">
        <adf-document-list
          #documentList
          acaDocumentList
          acaContextActions
          [display]="documentDisplayMode$ | async"
          [selectionMode]="'multiple'"
          [multiselect]="true"
          [currentFolderId]="node?.id"
          [loading]="true"
          [showHeader]="showHeader"
          [node]="nodeResult"
          [allowDropFiles]="true"
          [navigate]="false"
          [sorting]="['name', 'ASC']"
          [imageResolver]="imageResolver"
          [headerFilters]="true"
          [filterValue]="queryParams"
          (node-dblclick)="handleNodeClick($event)"
          (name-click)="handleNodeClick($event)"
          (sorting-changed)="onSortingChanged($event)"
          (filterSelection)="onFilterSelected($event)"
          (error)="onError()"
        >
          <data-columns>
            <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
              <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                >
                  <ng-template let-context>
                    <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                  </ng-template>
                </data-column>
              </ng-container>

              <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                >
                </data-column>
              </ng-container>
            </ng-container>
          </data-columns>

          <adf-custom-empty-content-template *ngIf="isFilterHeaderActive">
            <ng-container>
              <div class="empty-search__block" aria-live="polite">
                <p class="empty-search__text">
                  {{ 'APP.BROWSE.SEARCH.NO_FILTER_RESULTS' | translate }}
                </p>
              </div>
            </ng-container>
          </adf-custom-empty-content-template>
        </adf-document-list>

        <adf-pagination acaPagination [target]="documentList"> </adf-pagination>
      </adf-upload-drag-area>
    </div>

    <mat-progress-spinner *ngIf="showLoader$ | async"
      id="adf-document-list-loading"
      class="adf-document-list-loading-margin"
      [color]="'primary'"
      [mode]="'indeterminate'">
    </mat-progress-spinner>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
